import React, { FC, PropsWithChildren } from 'react';

type ShowProps = {
    condition: boolean;
    Otherwise?: FC | JSX.Element;
};
export const Show = ({ children, condition, Otherwise }: PropsWithChildren<ShowProps>): JSX.Element => (
    <>{condition ? children : Otherwise !== undefined ? Otherwise : <></>}</>
);

export default Show;
