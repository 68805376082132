import { useEffect, useState } from 'react';

/**
 * determine wether is client side or not, inside react component
 * @returns state managed bool wether its clientside
 */
export const useIsClientSide = () => {
    const [isClientSide, setIsClientSide] = useState(false);
    useEffect(() => {
        setIsClientSide(true);
    }, []);
    return isClientSide;
};
